
.creation-service-name {
  margin-top: 26px;
  margin-left: 66px;
}
::v-deep .el-input-number {
  .el-input__inner {
    text-align: left;
  }
}
